import { headers } from "./api.config";

const loginAdmin = async (data) => {
  const { email, password } = data;
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/merchant_users/login`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    }
  );

  if (response.ok) {
    const { success, data } = await response.json();
    const { token, permission } = await data;
    return { success, token, permission, data };
  } else {
    const { response_message } = await response.json();
    throw Error(response_message);
  }
};

export const changePass = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/admin/password/reset/${1}`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { success, response_message } = await response.json();
    return { success, response_message };
  }
};

export default loginAdmin;
