import {
  FILTER_INVESTMENT_REPORT_BY_DATE,
  FILTER_INVESTMENT_REPORT_BY_STATUS,
  GET_INVESTMENT_REPORT
} from "../action/action.types";

import { combineReducers } from "redux";

const initialState = {
  investmentReportsData: [],
  total: 0,
  response_msg: "",
};

const investmentReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
        update_message: "",
      };
      case GET_INVESTMENT_REPORT:
      return {
        ...state,
        loading: false,
        filtering: false,
        invest_report: action.payload.data,
      };
      case FILTER_INVESTMENT_REPORT_BY_DATE:
      return {
        ...state,
        loading: false,
        filtering: true,
        invest_report: action.payload.data,
      };

    case FILTER_INVESTMENT_REPORT_BY_STATUS:
      return {
        ...state,
        loading: false,
        filtering: true,
        invest_report: action.payload.data,
      };

    default:
      return state;
  }
};


export const reportsReducer = combineReducers({
  investmentReports: investmentReportsReducer,
});
