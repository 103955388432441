export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILED = "USER_FAILED";
export const ADMIN_SUCCESS = "ADMIN_SUCCESS";
export const ADMIN_FAILED = "ADMIN_FAILED";
export const LOGOUT = "LOGOUT";
export const DETAIL_INFO = "DETAIL_INFO";
export const INVESTMENT_PRODUCT_SUCCESS = "INVESTMENT_PRODUCT_SUCCESS"
export const INVESTMENT_PRODUCT_FAILED = "INVESTMENT_PRODUCT_FAILED"
export const PRODUCT_SUBSCRIPTION_FAILED = "PRODUCT_SUBSCRIPTION_FAILED"
export const PRODUCT_SUBSCRIPTION_SUCCESS = "PRODUCT_SUBSCRIPTION_SUCCESS"
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS"
export const PRODUCT_DETAILS_FAILED = "PRODUCT_DETAILS_FAILED"
export const INVESTMENT_RETURNS_FAILED = "INVESTMENT_RETURNS_FAILED"
export const INVESTMENT_RETURNS_SUCCESS = "INVESTMENT_RETURNS_SUCCESS"
export const GET_INVEST_CATEGORY = "GET_INVEST_CATEGORY"
export const ADD_INVEST_PRODUCT = "ADD_INVEST_PRODUCT"

export const FILTER_SUCCESS = "FILTER_SUCCESS";
export const SET_ROLE = "SET_ROLE";
export const GET_ROLE = "GET_ROLE";
export const SET_PERMISSION = "SET_PERMISSION";
export const GET_PERMISSION = "GET_PERMISSION";
export const SET_PERMISSION_MODULE = "SET_PERMISSION_MODULE";
export const GET_PERMISSION_MODULE = "GET_PERMISSION_MODULE";
export const SET_ADMIN_PERMISSION = "SET_ADMIN_PERMISSION";
export const GET_ADMIN_PERMISSION = "GET_ADMIN_PERMISSION";
export const DELETE_PERMISSION_LIST = "DELETE_PERMISSION_LIST";

export const ENABLE_USER = "ENABLE_USER";
export const DISABLE_USER = "DISABLE_USER";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const FILTER_USERS = "FILTER_USERS";

export const EDIT_INVEST_PROD = "EDIT_INVEST_PROD";
export const EDIT_INVEST_PROD_ERROR = "EDIT_INVEST_PROD_ERROR";
export const UPLOAD_IMG = "UPLOAD_IMG";
export const ROLES = "ROLES";
export const INVESTMENT_PROGRESS = "INVESTMENT_PROGRESS";
export const ADD_INVESTMENT_PROGRESS = "ADD_INVESTMENT_PROGRESS";
export const FILTER_INVESTMENT_BY_DATE = "FILTER_INVESTMENT_BY_DATE";
export const FILTER_INVESTMENT_BY_STATUS = "FILTER_INVESTMENT_BY_STATUS";
export const GET_INVEST_REPORT = "GET_INVEST_PRODUCT_REPORT";
export const FILTER_INVESTMENT_REPORT_BY_DATE = "FILTER_INVESTMENT_REPORT_BY_DATE";
export const FILTER_INVESTMENT_REPORT_BY_STATUS = "FILTER_INVESTMENT_REPORT_BY_STATUS";
export const GET_INVESTMENT_REPORT = "GET_INVESTMENT_REPORT";
export const GET_INVEST_PRODUCT = "GET_INVEST_PRODUCT";
