import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ManageProgress from "./ManageProgress";
import ProgressHistories from "./ProgressHistories"

const ProgressRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={ManageProgress} />
      <Route
        exact
        path={`${match.path}/:id/history`}
        component={ProgressHistories}
      />
    </Switch>
  </>
);
export default ProgressRoute;
