import React from "react";
import { Modal } from "../../components/Accessories";
import { connect } from "react-redux";
import { getAdminRole } from "../../reduxStore/action/action.creator";
import { dateFormatter } from "../../components/Row";

class AdminModal extends React.Component {
  state = {
    name: "",
    password: "",
    phone_number: "",
    created_by: this.props.merchant_data.id,
    email: "",
    company_id: this.props.merchant_data.company_id,
    role_id: "",
    enabled: true,
    created_date: Date.now()
  };

  componentDidMount() {
    this.props.getAdminRole();
  }

  componentDidUpdate(prevProps, prevState) {}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Add New Admin">
        <div className="body-grid">
          <div className="box box9">
            <label>Email</label>

            <div className="input-div">
              <input
                type="email"
                name="email"
                value={this.state.email}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="box box1">
            <label for="name">Name of Admin</label>

            <div className="input-div">
              <input
                type="text"
                name="name"
                value={this.state.name}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label for="limitDescription">Password</label>

            <div className="input-div">
              <input
                type="password"
                name="password"
                value={this.state.password}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1">
            <label>Staff Phone Number</label>

            <div className="input-div">
              <input
                name="phone_number"
                value={this.state.phone_number}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>Role Id</label>

            <div className="input-div">
              <select
                name="role_id"
                value={this.state.role_id}
                onChange={this.handleChange}>
                <option></option>
                {this.props.roles &&
                  this.props.roles.map((content) => (
                    <option value={content.id}>{content.name}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="box box1">
            <div className="row">
              <div className="col-md-12">
                <label>Lock Admin </label>
              </div>
            </div>

            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"

                checked={this.props.enabled}
                // data-toggle="modal"
                // data-target="#exampleModal"
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-accent"
                onClick={() => this.props.handleSubmit(this.state)}>
                Add new Admin
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  merchant_data: state.login.data,
  roles: state.users.alladmins.adminRolesData
});

export default connect(mapStateToProps, { getAdminRole })(AdminModal);
