import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

import { currencyFormatter, dateFormatter, statusButton, timeFormatter } from '../Row';
import { DateRange } from '../Accessories';
import { tabDetail } from '../../reduxStore/action/action.creator';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<Link
		to=''
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
		<span className='threedots' />
	</Link>
));

// table head for all users [Admin and Users]
export const ProductsTableHead = (props) => (
	<thead className='thead-light'>
		<tr>
			<th>S/N</th>
			<th>Product Name</th>
			<th>Product Description</th>
			<th>Status</th>
			<th>Created By</th>
			<th>Date Created</th>
			<th>Approval Date</th>
			<th>Total Slots</th>
			<th>Slots Taken</th>
			<th>Total Slots Value</th>
			<th>Slots Taken Value</th>
			<th>Total Returns</th>
			<th>Image</th>
			<th>More Options</th>
		</tr>
	</thead>
);

// 
export const ProgressTableHead = (props) => (
	<thead className='thead-light'>
	<tr>
		<th>S/N</th>
		<th>Product Name</th>
		<th>Product Description</th>
		<th>Status</th>
		<th>Created By</th>
		<th>Date Created</th>
		<th>Approval Date</th>
		<th>More Options</th>
	</tr>
</thead>
);

export const ProductSubscriptionsTableHead = (props) => (
	<thead className='thead-light'>
		<tr>
			<th>S/N</th>
			<th>Investment Cycle</th>
			<th>User Id</th>
			<th>Slots Taken</th>
			<th>Amount</th>
			<th>Start Date</th>
			<th>End Date</th>
			<th>Accrued Interest</th>
			<th>Status</th>
			<th>Recurrent Subscription</th>
		</tr>
	</thead>
);

export const InvestmentProgressHistoryTableHead = (props) => (
	<thead className='thead-light'>
		<tr>
			<th>S/N</th>
			<th>Investment Product</th>
			<th>Progress Title</th>
			<th>Progress Description</th>
			<th>Progress Report Date</th>
			<th>Progress Report Time</th>
		</tr>
	</thead>
);

export const InvestmentProgressHistoryRow = (props) => (
	<>
		<tr key={props.index}>
			<td>{props.index}</td>
			<td>{props.investment_product_id}</td>
			<td>{props.progress_name}</td>
			<td>{props.description}</td>
			<td>{dateFormatter(props.created_at)}</td>
			<td>{timeFormatter(props.created_at)}</td>
		</tr>
	</>
);

/** body rows for all user */
export const ProductsRow = (props) => (
	<>
		<tr key={props.index}>
			<td>{props.index}</td>
			<td>{props.name}</td>
			<td>{props.description}</td>
			<td>{props.status}</td>
			<td>{props.created_by}</td>
			<td>{dateFormatter(props.created_date)}</td>
			<td>{dateFormatter(props.approval_date)}</td>
			<td>{props.investment_cycle?.slot || 0}</td>
			<td>{props.investment_cycle?.slots_taken || 0}</td>
			<td>{props.investment_cycle?.total_investment_sum || 0}</td>
			<td>{currencyFormatter(props.investment_cycle?.slots_taken || 0 * props.investment_cycle?.cost_per_slot || 0)}</td>
			<td>
				{currencyFormatter(
					props.investment_cycle?.slots_taken *
						props.investment_cycle?.cost_per_slot *
						(props.investment_cycle?.ROI_percentage / 100) || 0
				)}
			</td>
			<td><img style={{width: '40px', }} src={props.image_url} /></td>

			<td
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Dropdown>
					<Dropdown.Toggle as={CustomToggle} />
					<Dropdown.Menu size='sm' title='' className='dotsText'>
						<Dropdown.Item>
							<Link to={`/allproducts/${props.id}/details`} className='dotsText'>
								Product Details
							</Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Link to={`/allproducts/${props.id}/subscriptions`} className='dotsText'>
								Product Subcriptions
							</Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Link to={`/allproducts/${props.id}/return_payments`} className='dotsText'>
								Return Payments
							</Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Link onClick={() => props.editProduct(props)} className='dotsText'>
								Edit Product
							</Link>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</td>
		</tr>
	</>
);

export const ProgressRow = (props) => (
	<>
		<tr key={props.index}>
			<td>{props.index}</td>
			<td>{props.name}</td>
			<td>{props.description}</td>
			<td>{props.status}</td>
			<td>{props.created_by}</td>
			<td>{dateFormatter(props.created_date)}</td>
			<td>{dateFormatter(props.approval_date)}</td>

			<td
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Dropdown>
					<Dropdown.Toggle as={CustomToggle} />
					<Dropdown.Menu size='sm' title='' className='dotsText'>
						<Dropdown.Item>
							<Link
								onClick={() => props.addProgress(props)} // to change to ""add progress modal"
								className='dotsText'
							>
								Add Progress Report
							</Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Link to={`/progress/${props.id}/history`} className='dotsText'>
								Progress History
							</Link>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</td>
		</tr>
	</>
);

export const ProductSubscriptionsRow = (props) => (
	<>
		<tr key={props.index}>
			<td>{props.index}</td>
			<td>{props.product_investment_cycle_id}</td>
			<td>{props.user_id}</td>
			<td>{props.slots}</td>
			<td>{props.amount}</td>
			<td>{dateFormatter(props.start_date)}</td>
			<td>{dateFormatter(props.end_date)}</td>
			<td>{props.accrued_interest}</td>
			<td>{props.status}</td>
			<td>{props.recurrent_subscription}</td>
		</tr>
	</>
);

export const ProductDetailsList = (props) => (
	<>
		<div class='mt-4 ml-3 body-grid'>
			<div className='box box3'>
				<p
					style={{
						fontWeight: 'bold',
						fontSize: '18px',
						color: 'black',
						marginTop: '1em',
						marginBottom: '0em',
						marginLeft: '-1em',
					}}
				>
					{props.name}
				</p>
				{/* <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "0em",
            }}>
          </p> */}
			</div>
			<div
				className='box box10'
				style={{
					display: 'grid',
					justifyContent: 'flex-end',
					alignItems: 'center',
				}}
			></div>
		</div>
		<table class='table table-hover mt-1' style={{ textAlign: 'left' }}>
			<tbody>
				<tr>
					<td>Description</td>
					<td style={{ textAlign: 'right', fontWeight: 'bold' }}>{props.description}</td>
				</tr>
				<tr>
					<td>Recurring</td>
					<td style={{ textAlign: 'right', fontWeight: 'bold' }}>{props.recurring == true ? 'true' : 'false'}</td>
				</tr>
				<tr>
					<td>Status</td>
					<td style={{ textAlign: 'right', fontWeight: 'bold' }}>{props.status}</td>
				</tr>
				<tr>
					<td>Created By</td>
					<td style={{ textAlign: 'right', fontWeight: 'bold' }}>{props.created_by}</td>
				</tr>
				<tr>
					<td>Approved By</td>
					<td style={{ textAlign: 'right', fontWeight: 'bold' }}>{props.approved_by}</td>
				</tr>
				<tr>
					<td>Return Type</td>
					<td style={{ textAlign: 'right', fontWeight: 'bold' }}>{props.return_type}</td>
				</tr>
				<tr>
					<td>Date Created</td>
					<td style={{ textAlign: 'right', fontWeight: 'bold' }}>{dateFormatter(props.created_date)}</td>
				</tr>
				<tr>
					<td>Date Approved</td>
					<td style={{ textAlign: 'right', fontWeight: 'bold' }}>{dateFormatter(props.approval_date)}</td>
				</tr>
				<tr>
					<td>Product Reference</td>
					<td style={{ textAlign: 'right', fontWeight: 'bold' }}>{props.product_ref}</td>
				</tr>
				<tr>
					<td>Product Category</td>
					<td style={{ textAlign: 'right', fontWeight: 'bold' }}>{props.category.name}</td>
				</tr>
				<tr>
					<td>Feature Image</td>
					<td style={{ textAlign: 'right' }}>
						<img src={props.image_url} alt='' width='80px' />
					</td>
				</tr>
			</tbody>
		</table>
	</>
);
