import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { usersReducer } from "./alluserReducer";
import { productsReducer } from "./productsReducer";
import { reportsReducer } from "./reportsReducer";

const reducer = combineReducers({
  login: loginReducer,
  users: usersReducer,
  products: productsReducer,
  reports: reportsReducer
});

export default reducer;
