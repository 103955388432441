import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { ProgressTableHead, ProgressRow } from "../../components/Rows";
import { callBackMethod, Loader } from "../../components/Accessories";
import AddInvestProgressModal from "./ProgressModal";

import "../../morestyles.css";
import {
  getProducts,
  // getInvestmentCategory,
  addProgressHistory,
  // uploadProductImage,
  // editInvestmentProduct,
} from "../../reduxStore/action/action.creator";

class ManageProgress extends React.Component {
  state = {
    currentPage: 1,
    display: false,
    // display2: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.success == true) {
      this.setState({
        display2: false,
      });
      this.props.getProducts(this.props.id, this.props.token); // trying to pass down the merchant_id here along side token 
    }
  }

  componentDidMount() {
    this.props.getProducts(this.props.merchant_data.id, this.props.token);
    // this.props.getInvestmentCategory(
    //   "/savinvest/investment_categories",
    //   this.props.token
    // );
  }

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  handleNextPage = (props) => {
    const { index } = props;
    this.props.detailsInfo(props);
    this.props.history.push(`${this.props.match.url}/${index}/details`);
  };

  addNew = () => {
    this.setState({
      display: true,
    });
  };

  showModal = (data) => {
    this.setState({
      ...this.state,
      display: true,
      id: data.id
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      display2: false,
      upload: false,
      edit_modal: false,
      detail_modal: false
    });
  };

  appoveModal = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      upload: true,
    });
  };

  handleFile = (e) => {
    this.setState({
      image: e.target.files[0],
      file: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleChange = (e) => {
    this.setState({
      product: e.target.value,
      name: "item name",
    });
  };

  editProd = (props) => {
    this.setState({
      ...this.state,
      edit_modal: true,
      editData: props,
    });
  };

  addProgress = (data) => { // work on for adding progress
    callBackMethod(
      this.props.addProgressHistory(
        "/savinvest/investment_progress_histories",
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ display: false }))
      .then(() => {
        this.props.history.push(`progress/${this.state.id}/history`)
      })
      // .then(() =>
      //     this.props.getProducts(this.props.merchant_data.id, this.props.token) // how do i get the id of the product here ?
      // );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <ProgressTableHead admin="true" />
          <tbody>
            {this.props.products && this.props.products.map((info, index) => (
              <ProgressRow
                {...info}
                // admin="true"
                index={this.getIndex(index + 1)}
                addProgress={this.showModal}
              />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="All Products"
              getInfo={this.props.getProducts}
              addNew={this.addNew}
            />

            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header inline-space">
                      <h3 class="mb-0">Manage Investment Progress</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic" style={{marginBottom: '100px'}}>
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddInvestProgressModal
          display={this.state.display}
          closeModal={this.closeModal}
          addProgress={this.addProgress}
          id={this.state.id}
        /> 
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.products.allProducts.loading,
  products: state.products.allProducts.productsData,
  token: state.login.token,
  err: state.products.allProducts.failed,
  merchant_data: state.login.data,
});

export default connect(mapStateToProps, {
  getProducts,
  addProgressHistory,
})(ManageProgress);
