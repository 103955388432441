import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { propTypes } from "react-bootstrap/esm/Image";

const SideBar = ({ match, ...props }) => {
  // alert(match.url);
  return (
    <nav
      className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
      id="sidenav-main">
      <div className="scrollbar-inner">
        <div className="sidenav-header  d-flex  align-items-center">
          <a
            className="navbar-brand"
            href="../../pages/dashboards/dashboard.html">
            <img
              src="../../assets/img/brand/getrivest-logo.png"
              className="navbar-brand-img"
              alt="..."
            />
          </a>
          <div className=" ml-auto ">
            <div
              className="sidenav-toggler d-none d-xl-block"
              data-action="sidenav-unpin"
              data-target="#sidenav-main">
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <div className="collapse navbar-collapse" id="sidenav-collapse-main">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  to={`/home`}
                  className="nav-link active"
                  aria-controls="navbar-dashboards">
                  <i className="ni ni-shop text-primary"></i>
                  <span className="nav-link-text">Dashboard</span>
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#navbar-examples"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="navbar-examples">
                  <i className="ni ni-briefcase-24 text-orange"></i>
                  <span className="nav-link-text">Investment Products</span>
                </a>
                <div className="collapse" id="navbar-examples">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to={`/allproducts`}
                        className="nav-link"
                        aria-controls="navbar-dashboards">
                        <span className="sidenav-mini-icon"> A </span>
                        <span className="sidenav-normal"> View Products </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#navbar-service"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="navbar-service">
                  <i class="ni ni-money-coins text-orange"></i>
                  <span className="nav-link-text">Investment Progress</span>
                </a>
                <div className="collapse" id="navbar-service">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link to={`/progress`} className="nav-link">
                        <span className="sidenav-mini-icon"> P </span>
                        <span className="sidenav-normal">
                          Manage Progress
                        </span>
                      </Link>
                    </li>
                   
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#navbar-components1"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="navbar-components1">
                  <i className="ni ni-chart-pie-35 text-orange"></i>
                  <span className="nav-link-text">Reports</span>
                </a>
                <div className="collapse" id="navbar-components1">
                  <ul className="nav nav-sm flex-column">

                    <li className="nav-item">
                      <Link to={`/report`} className="nav-link">
                        <span className="sidenav-mini-icon"> S </span>
                        <span className="sidenav-normal"> Report Summary </span>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link to={`/report/investment-report`} className="nav-link">
                        <span className="sidenav-mini-icon"> I </span>
                        <span className="sidenav-normal"> Investment Report </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={`/report/subscription-report`} className="nav-link">
                        <span className="sidenav-mini-icon"> S </span>
                        <span className="sidenav-normal"> Subscription Report </span>
                      </Link>
                    </li> */}

                  </ul>
                </div>
              </li>

            </ul>
            <hr className="my-3" />
            <h6 className="navbar-heading p-0 text-muted">
              <span className="docs-normal">More Options</span>
              <span className="docs-mini">M</span>
            </h6>
            <ul className="navbar-nav mb-md-3">

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#navbar-components"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="navbar-components">
                  <i className="fa fa-users text-orange"></i>
                  <span className="nav-link-text">Manage Admins</span>
                </a>
                <div className="collapse" id="navbar-components">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link to={`/alladmin`} className="nav-link">
                        <span className="sidenav-mini-icon"> A </span>
                        <span className="sidenav-normal"> All Admins </span>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link" to={`#`}>
                  <i className="ni ni-ui-04 text-info"></i>
                  <span className="nav-link-text">Audit Logs</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  permission: state.login.permission,
});

export default connect(mapStateToProps, {})(SideBar);
