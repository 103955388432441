import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { ProductSubscriptionsTableHead, ProductSubscriptionsRow } from "../../components/Rows";
// import InvestmentBar from "../InvestmentBar";
import { callBackMethod, Loader } from "../../components/Accessories";
import ProductModal, { UploadModal } from "./ProductModal";
import ProductEditModal from "./ProductEditModal";

import "../../morestyles.css";
import {
  getProductSubscriptions,
} from "../../reduxStore/action/action.creator";
// import { useParams } from "react-router-dom";

class ProductReturnPayments extends React.Component {
  state = {
    currentPage: 1,
    display: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.success == true) {
      this.setState({
        display2: false,
      });
     }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {

    let urlParam = this.props.history.location.pathname;
    urlParam = urlParam.substring(urlParam.indexOf("s/") + 2, urlParam.indexOf("/s"));

    this.props.getProductSubscriptions(urlParam, this.props.token); // to be replaced with id of product
  }

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <ProductSubscriptionsTableHead admin="true" />
          <tbody>
            {this.props.productSubs && this.props.productSubs.map((info, index) => (
              <ProductSubscriptionsRow
                {...info}
                index={this.getIndex(index + 1)}
              />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Product Subscriptions"
              getInfo={this.props.getProductSubscriptions}
              addNew={this.addNew}
            />

            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header inline-space">
                      <h3 class="mb-0"> {this.props.productSubs[0]?.name} - Return Payments</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </>
    );
  }
}

const mapStateToProps = (state) => ({ // still using product subs here but the all the redux steps for returns have been done, only to be read on this page
  loading: state.products.productSubscriptions.loading,
  productSubs: state.products.productSubscriptions.productSubsData,
  total: state.products.productSubscriptions.total,
  err: state.products.productSubscriptions.failed,
  token: state.login.token,
  merchant_data: state.login.data
});
export default connect(mapStateToProps, {
  getProductSubscriptions,
})(ProductReturnPayments);
