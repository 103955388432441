import {
  USER_FAILED,
  USER_SUCCESS,
  ADMIN_FAILED,
  ADMIN_SUCCESS,
  GET_BEN_FAILED,
  GET_BEN_SUCCESS,
  SET_BENEFICIARY_SUCCESS,
  SET_BENEFICIARY_FAILURE,
  GET_PARTNER_SUCCESS,
  FILTER_SUCCESS,
  ENABLE_USER,
  DISABLE_USER,
  UPDATE_ADMIN,
  FILTER_USERS,
  GET_DETAIL_SUCCESS,
  ROLES
} from "../action/action.types";

import { combineReducers } from "redux";

const initialState = {
  usersData: [],
  adminData: [],
  bendata: [],
  adminRolesData: [],
  total: 0,
  response_msg: "",
};

const alluserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
      };
    case USER_SUCCESS:
      return {
        ...state,
        usersData: action.payload.users,
        total: action.payload.total,
        loading: false,
        filtering: false,
        response_msg: "",
      };
    case FILTER_SUCCESS:
      return {
        ...state,
        filtering: true,
        usersData: action.payload.users,
        total: action.payload.total,
        loading: false,
      };
    case ENABLE_USER:
      return {
        ...state,
        response_msg: action.payload.Response_message,
      };
    case DISABLE_USER:
      return {
        ...state,
        response_msg: action.payload.Response_message,
      };
    case FILTER_USERS:
      return {
        ...state,
        usersData: action.payload.users,
        total: action.payload.total,
        loading: false,
      };
    case USER_FAILED:
      return {
        ...state,
        failed: action.payload,
        filtering: false,
      };
    default:
      return state;
  }
};

// const userDetailReducer = (state = {}, action) => {
//   switch (action.type) {
//     case "LOADING":
//       return {
//         ...state,
//         loading: true,
//       };
//     case GET_DETAIL_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         user_details: action.payload.result,
//       };
//     default:
//       return state;
//   }
// };

// const beneficiariesReducer = (state = {}, action) => {
//   switch (action.type) {
//     case "LOADING":
//       console.log("loading");
//       return {
//         ...state,
//         beneficiaryResponse: false,
//         loading: true,
//       };
//     case SET_BENEFICIARY_SUCCESS:
//       return {
//         ...state,
//         beneficiaryResponse: true,
//         loading: false,
//       };
//     case GET_BEN_SUCCESS:
//       return {
//         beneficiaryResponse: false,
//       };
//     case SET_BENEFICIARY_FAILURE:
//       return {
//         ...state,
//         beneficiaryResponse: false,
//         loading: false,
//         failed: action.payload,
//       };
//     default:
//       return state;
//   }
// };

const allAdminsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
        update_message: "",
      };
    case ADMIN_SUCCESS:
      return {
        ...state,
        adminData: action.payload.admin_info,
        total: action.payload.total,
        loading: false,
      };
    case UPDATE_ADMIN:
      return {
        ...state,
        update_message: action.payload.success,
      };
    case ADMIN_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    case ROLES:
      return {
        ...state,
        adminRolesData: action.payload.admin_roles,
      }
    default:
      return state;
  }
};

export const usersReducer = combineReducers({
  allusers: alluserReducer,
  alladmins: allAdminsReducer,
  // allben: beneficiary,
  // setben: beneficiariesReducer,
  // userInfo: userDetailReducer,
});
