import React from "react";
import { Modal } from "../../components/Accessories";
import { connect } from "react-redux";
import { getAdminRole } from "../../reduxStore/action/action.creator";

class AdminEditModal extends React.Component {
  state = {
    username: "",
    password: "",
    email: "",
    // staff_id: "",
    role_id: "",
    lock: false,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.adminData != prevProps.adminData) {
      this.setState({
        email: this.props.adminData.email,
        username: this.props.adminData.username,
        password: "",
        role_id: this.props.adminData.role_id,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Change Admin  Password">
        <div className="body-grid">
          <div className="box box1">
            <label>Email</label>

            <div className="input-div">
              <input
                type="email"
                name="email"
                value={this.state.email}
                id=""
                disabled
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="box box2">
            <label for="fee-name">Username</label>

            <div className="input-div">
              <input
                type="text"
                name="username"
                value={this.state.username}
                id=""
                disabled
                onChange={this.handleChange}
              />
            </div>
          </div>

          <br />
          <div className="box box9">
            <label>New Admin password</label>

            <div className="input-div">
              <input
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-accent"
                onClick={() => this.props.handleEdit(this.state)}>
                Change Password
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  // data: state.roles.getrole.data,
});

export default connect(mapStateToProps, { getAdminRole })(AdminEditModal);
