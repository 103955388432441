import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AllProducts from "./AllProducts";
import ProductDetails from "./ProductDetails";
import ProductSubscriptions from "./ProductSubscriptions";
import ReturnPayments from "./ProductReturnPayments"

const ProductsRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={AllProducts} />
      <Route
        exact
        path={`${match.path}/:id/details`}
        component={ProductDetails}
      />
      <Route
        exact
        path={`${match.path}/:id/subscriptions`}
        component={ProductSubscriptions}
      />
      <Route
        exact
        path={`${match.path}/:id/return_payments`}
        component={ReturnPayments}
      />
    </Switch>
  </>
);
export default ProductsRoute;
