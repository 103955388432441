import { headers } from "./api.config";

export const fetchProducts = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/merchant/investment_products/${id}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, data } = await response.json();
    // const { total } = await data;
    const product_info = data.data;
    console.log('product info', product_info);
    return { product_info, success };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

export const fetchProductDetails = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/investment_products/${id}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, data } = await response.json();
    const product_data = await data;
    console.log('product details', product_data);
    return { product_data, success };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

export const fetchProductSubscriptions = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/product/investment_subscriptions/${id}`,
    {
      headers: headers(token),
    }
  );

  console.log('response', response);

  if (response.ok) {
    const { success, data, total } = await response.json();
    // const { total } = await data;
    const product_subscriptions = data;
    console.log('product_subs', product_subscriptions);
    return { product_subscriptions, success, total };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

export const fetchInvestmentProgressHistory = async (id, token) => {
  const response = await fetch(
    // `${process.env.REACT_APP_BASE_URL}/savinvest/investment_progress_histories/${id}`,
    `${process.env.REACT_APP_BASE_URL}/savinvest/product/investment_progress_histories/${id}`,
    {
      headers: headers(token),
    }
  );

  // console.log('response', response);

  if (response.ok) {
    const { success, data, total } = await response.json();
    const investment_progress = data;
    // console.log('investment_progress', investment_progress);
    return { investment_progress, success, total };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

export const fetchProductPaymentReturns = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/investment_returns/${id}`,
    {
      headers: headers(token),
    }
  );

  // console.log('response', response);

  if (response.ok) {
    const { success, data, total } = await response.json();
    // const { total } = await data;
    const investment_returns = data;
    console.log('investment_returns', investment_returns);
    return { investment_returns, success, total };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

// To use centrally
export const fetchInvestmentData = async (url_path, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url_path}`, {
    headers: headers(token),
  });
  if (response.ok) {
    const { data, success } = await response.json();
    return { success, data };
  }
};

// To use centrally
export const addInvestmentData = async (
  url_path,
  data,
  token,
  type = "POST"
) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url_path}`, {
    method: type,
    headers: headers(token),
    body: JSON.stringify(data),
  });
  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

export const uploadImage = async (id, form_data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/investment_product_image/${id}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: form_data,
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log('upload respose from API', data)
    return { data };
  }
};

export const lockProduct = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}`, // to work on - could be required if merchants are to lock products
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, Response_message } = await response.json();
    return { success, Response_message };
  }
};

export const editProduct = async (id, data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/admin_users/${id}`, // to work on
    {
      method: "PUT",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { success, response_message } = await response.json();
    return { success, response_message };
  }
};

// export const filterProducts = async (data, token) => {
//   const response = await fetch(
//     `${process.env.REACT_APP_BASE_URL}/user/search/users`, // change url and other fileds here to suite
//     {
//       method: "POST",
//       headers: headers(token),
//       body: JSON.stringify({
//         words: data.email || data.name || data.phone || data.username,
//       }),
//     }
//   );
//   if (response.ok) {
//     const { success, data } = await response.json();
//     const { total } = await data;
//     const result = data.data;

//     const users = result.map(processUser);
//     console.log(users + "" + total);
//     return { users, success, total };
//   } else {
//   }
// };

export const filterProductsByRange = async (startDate, endDate, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/users-all/${startDate}/${endDate}`, // change url here
    {
      headers: headers(token),
    }
  );
};

