import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../components/Accessories";

class AddInvestProgressModal extends React.Component {
  state = {
    progress_name: "",
    description: "",
    investment_product_id: "",
  };

  componentDidUpdate(prevProps, prevState){
    if(this.props.id != prevProps.id){
      this.setState({
        investment_product_id: this.props.id
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleSubmit = () => {
    this.props.addProgress(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Add Investment Progress">
        <div className="body-grid">
          <div className="box box9">
            <label>Progress Title</label>
            <input
              className="form-control"
              name="progress_name"
              value={this.state.progress_name}
              onChange={this.handleChange}
            />
          </div>

          <div className="box box9">
            <label>Progress Description</label>
            <input
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
            />
          </div>

          {/* <div className="box box1">
            <label>Investment Category</label>
            <select
              name="category_id"
              className="form-control"
              value={this.state.category_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.invest_category &&
                this.props.invest_category.map((content) => (
                  <option value={content.id}>{content.name}</option>
                ))}
            </select>
          </div> */}
{/* 
          <div className="box box1">
            <label>Name of Investment Cycle</label>
            <input
              className="form-control"
              name="cycle_name"
              value={this.state.cycle_name}
              onChange={this.handleChange}
            />
          </div> */}

          {/* <div className="box box2">
            <label>Total Investment Sum</label>
            <input
              className="form-control"
              name="total_investment_sum"
              value={this.state.total_investment_sum}
              onChange={this.handleChange}
            />
          </div> */}

          {/* <div className="box box1">
            <label>ROI percentage</label>
            <input
              className="form-control"
              name="ROI_percentage"
              value={this.state.ROI_percentage}
              onChange={this.handleChange}
            />
          </div> */}

          {/* <div className="box box2">
            <label>Total Expected Slot</label>
            <input
              className="form-control"
              name="slot"
              value={this.state.slot}
              onChange={this.handleChange}
            />
          </div> */}
          
          
          {/* <div className="box box1">
            <div className="row">
              <div className="col-md-12">
                <label>Re occuring </label>
              </div>
            </div>

            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="recurring"
                checked={this.state.recurring}
                onChange={this.handleCheckBox}
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div> */}
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-accent"
                onClick={this.handleSubmit}>
                Add Progress Report
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

// export const UploadModal = (props) => {
//   return (
//     <Modal
//       display={props.display ? "block" : "none"}
//       closeModal={props.closeModal}
//       modalTitle="Upload Product Image">
//       <div className="body-grid">
//         <div className="box box9">
//           <label>Choose Investment Product</label>
//           <select
//             className="form-control"
//             name="product_id"
//             value={props.product_id}
//             onChange={props.handleChange}>
//             <option></option>
//             {props.invest_prod &&
//               props.invest_prod.map((content) => (
//                 <option value={content.id}>{content.name}</option>
//               ))}
//           </select>
//         </div>
//         <div className="box box9">
//           <label>Upload Image</label>
//           <input
//             className="form-control"
//             type="file"
//             name="image"
//             accept="image/png, image/jpeg"
//             onChange={props.handleFile}
//           />
//         </div>
//         <div className="box box9">
//           <div className="text-center">
//             <br/>
//             <button
//               type="button"
//               class="btn btn-accent"
//               onClick={props.handleSubmit}>
//               Upload Image
//             </button>
//           </div>
//         </div>
//       </div>
//     </Modal>
//   );
// };

const mapStateToProps = (state) => ({
  token: state.login.token,
  merchant_data: state.login.data,
  // admins: state.users.alladmins.adminData,
  // merchant_data: state.invest.getData.merchant_data,
  // invest_category: state.products.allProducts.invest_category,
});

export default connect(mapStateToProps, {})(AddInvestProgressModal);
