import React from 'react';
import { connect } from 'react-redux';
import SideBar from '../../components/SideBar';
import DashboardNavBar from '../../components/DashboardNavbar';
// import { InvestmentReportRequest } from '../../API/ReportRequest';
import { Link } from 'react-router-dom';
import { callBackMethod, SingleCard, DateRange } from '../../components/Accessories';
import { filterInvestmentReport, getInvestmentProductReport, } from '../../reduxStore/action/action.creator';
import '../../morestyles.css';

class Report extends React.Component {
	state = {
		investmentRportData: {},
		filterby: '',
		showBtnDate: false,
		showDropStatus: false,
		startDate: null,
		endDate: null,
		status: '',
	};

	componentDidMount() {
		// this.getInvestmentReport();
		this.props.getInvestmentProductReport(
      `/savinvest/investment_analysis/merchant_users/${this.props.merchant_data.id}`,
      this.props.token
    );
	}

	filterByType = (e) => {
		callBackMethod(
			this.setState({
				filterby: e.target.value,
			})
		).then(() => this.runByType(this.state.filterby));
	};

	runByType = (type) => {
		if (type == 'status') {
			this.setState({
				showDropStatus: true,
				showBtnDate: false,
			});
		} else {
			this.setState({
				showBtnDate: true,
				showDropStatus: false,
			});
		}
	};

	handleRange = (event, picker) => {
		let start = new Date(picker.startDate).toISOString();
		let end = new Date(picker.endDate).toISOString();

		start = start.substring(0, start.indexOf('T'));
		end = end.substring(0, end.indexOf('T'));

		callBackMethod(
			this.setState({
				startDate: start,
				endDate: end,
			})
		).then(() =>
			this.props.filterInvestmentReport(
				'date',
				`/savinvest/investment_analysis/merchant_users/${this.props.merchant_data.id}?from=${this.state.startDate}&to=${this.state.endDate}`, 
				this.props.token
			)
		);
	};

	handleStatusFilter = (e) => {
    callBackMethod(
      this.setState({
        status: e.target.value,
      })
    ).then(() =>
      this.props.filterInvestmentReport(
        "status",
        `/savinvest/investment_analysis/merchant_users/${this.props.merchant_data.id}?status=${this.state.status}`,
        this.props.token
      )
    );
  };

	render() {
		return (
			<div>
				<SideBar />
				<div class='main-content' id='panel'>
					<DashboardNavBar
						submenu='Report Summary'
					/>
					<div class='container-fluid mt--6'>
						<div class='row align-items-center py-4'>
							<div class='col'>
								<div class='card bg-accent'>
									<div class='card-header' style={{ backgroundColor: '#E6E6FA' }}>
										<div className='row'>
											<div className='col-12'>
												<div className='col-12 text-left'>
													<div style={{ display: 'flex', alignItems: 'center' }}>
														<select
															value={this.state.filterby}
															className='form-control'
															style={{ width: '20em' }}
															onChange={this.filterByType}
														>
															<option>filter by...</option>
															<option value='status'>filter by status</option>
															<option value='date'>filter by date</option>
														</select>

														<div style={{ paddingLeft: '1em' }}>
															{this.state.showBtnDate && <DateRange handleRange={this.handleRange} />}

															{this.state.showDropStatus && (
																<select
																	style={{ width: '15em' }}
																	onChange={this.handleStatusFilter}
																	className='form-control'
																>
																	<option>Filter by...</option>
																	<option value='active'>Active</option>
																	<option value='in-active'>In-Active</option>
																	<option value='pending'>Pending</option>
																</select>
															)}
														</div>
													</div>
												</div>
												{/* filter buttons here */}
											</div>
										</div>
									
									</div>
								</div>

								<div class='card bg-accent'>
									<div class='card-header' style={{ backgroundColor: '#E6E6FA' }}>
										<div className='row'>
											<div className='col-md-6'>
												<span style={{ fontSize: '18px', fontWeight: '600' }} contentEditable>
													Investment Report
												</span>
											</div>
											<div className='col-md-6'>
												<div className='text-right'>
													<Link to={`${this.props.match.url}/investment-report`} class='btn btn-sm btn-neutral'>
														View Report Summary
													</Link>
												</div>
											</div>
										</div>
									</div>
									<div class='card-body'>
										<div className='row'>
											{/* {this.state.totalUserSum.map((content) => ( */}
											{/* <SingleCard {...content} /> */}
											<SingleCard topic={'Total Slots'} summedAmount={this.props.investmentReportData?.total_slots} />
											<SingleCard
												topic={'Total Returns'}
												summedAmount={this.props.investmentReportData?.total_returns}
											/>
											<SingleCard topic={'Total Amount'} summedAmount={this.props.investmentReportData?.total_amount} />
											<SingleCard
												topic={'Total Investment'}
												summedAmount={this.props.investmentReportData?.total_investment}
											/>
											{/* ))} */}
										</div>
									</div>
								</div>

								<div class='card bg-accent'>
									<div class='card-header' style={{ backgroundColor: '#E6E6FA' }}>
										<div className='row'>
											<div className='col-md-6'>
												<span style={{ fontSize: '18px', fontWeight: '600' }}>Subscription Summary Reports</span>
											</div>
											<div className='col-md-6'>
												<div className='text-right'>
													<Link to={`${this.props.match.url}/investment-report`} class='btn btn-sm btn-neutral'>
														View Report Summary
													</Link>
												</div>
											</div>
										</div>
									</div>

									<div class='card-body'>
										<div className='row'>
											{/* {this.state.totalTrans.map((content, index) => ( */}
											{/* <SingleCard isTransaction={true} {...content} /> */}
											<SingleCard summedAmount={0} />
											{/* ))} */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	token: state.login.token,
	investmentReportData: state.reports.investmentReports.invest_report,
	merchant_data: state.login.data,
});

export default connect(mapStateToProps, {
  filterInvestmentReport,
	getInvestmentProductReport
})(Report);
