import React from "react";
import { connect } from "react-redux";
import { Dashboard } from "../pages/DashBoard";
import { AdminProfile } from "../pages/ProfilePage";
import { Report } from "../pages/Report";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ProductsRoute } from "../pages/InvestmentProducts";
import { ProgressRoute } from "../pages/InvestmentProgress"
// import { AllUserRoute } from "../pages/AllUsers";
import { AllAdminRoute } from "../pages/AllAdmin";
// import { AuditLogsRoute } from "../pages/AuditLogs";
// import { TransactionRoute } from "../pages/Transaction";
// import { BankTransactRoute } from "../pages/BankTransaction";
// import { FlutterwaveRoute } from "../pages/Flutterwave";
// import { NotificationRoute } from "../pages/Notification/NotificationRoute";
// import { BillerRoute } from "../pages/Bills";
// import { LoanRequestRoute } from "../pages/LoanRequest";
// import { SettingRoute } from "../pages/Settings";
// import { VirtualCardRoute } from "../pages/Cards/VirtualCards";
// import { Error } from "../pages/Error";
// import { DeveloperAccountsRoute } from "../pages/DeveloperAccounts"
import {
  getPermissionMod,
  getUsers,
  logOut,
} from "../reduxStore/action/action.creator";
import { LogOutModal } from "../components/Accessories";

class Routes extends React.Component {
  state = {
    isAllowed: [],
    display: false,
    countDown: 10,
  };

  componentDidMount() {
    
    // this.props.permission.map((permit, index) => {
    //   this.state.isAllowed.push(permit.permissions);
    // });

    this.props.getUsers(this.props.token);
  }

  componentWillReceiveProps(nextProps) {
   
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  render() {
    return (
      <>
        <LogOutModal
          display={this.state.display}
          closeModal={this.closeModal}
        />
        <Switch>
          <Route exact path="/home" component={Dashboard} />
          <Route exact path="/profile" component={AdminProfile} />
          <Route path="/allproducts" component={ProductsRoute} />
          <Route path="/alladmin" component={AllAdminRoute} />
          <Route path="/progress" component={ProgressRoute} />
          {/* {this.state.isAllowed.includes("Customer") && (
            <Route path="/allusers" component={AllUserRoute} />
          )} */}
          {/* <Route path="/auditlogs" component={AuditLogsRoute} /> */}
          {/* <Route path="/developer-accounts" component={DeveloperAccountsRoute} /> */}
          {/* {this.state.isAllowed.includes("Transaction") && (
            <Route
              path="/transactions"
              render={(props) => <TransactionRoute {...props} />}
            />
          )} */}
          {/* {this.state.isAllowed.includes("Bank Transaction") && ( */}
          {/* <Route path="/bank-transaction" component={BankTransactRoute} /> */}
          {/* )} */}
          {/* {this.state.isAllowed.includes("Flutterwave Transaction") && (
            <Route path="/flutterwave-transact" component={FlutterwaveRoute} />
          )} */}
          {/* {this.state.isAllowed.includes("Bills") && (
            <Route path="/bills" component={BillerRoute} />
          )} */}
          {/* {this.state.isAllowed.includes("Notification") && (
            <Route path="/notification" component={NotificationRoute} />
          )} */}
          {/* {this.state.isAllowed.includes("Loan Request") && (
            <Route
              path="/loan-request"
              render={(props) => <LoanRequestRoute {...props} />}
            />
          )} */}
          {/* {this.state.isAllowed.includes("Report") && (
            <Route path="/report" component={Report} />
          )} */}
            <Route path="/report" component={Report} />
          {/* <Route path="/settings" component={SettingRoute} /> */}
          {/* <Route path="/virtual-card" component={VirtualCardRoute} /> */}
          <Route exact component={Error} />
        </Switch>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  permission: state.login.permission,
  username: state.login.data.username,
  // data: state.roles.getpermissionmod.data,
  err: state.users.allusers.failed,
  token: state.login.token,
});
export default connect(mapStateToProps, { getPermissionMod, getUsers, logOut })(
  Routes
);
