import React from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { TableHead, Row } from "../../components/Rows";
import "../../morestyles.css";
import {
  getAdmins,
  detailsInfo,
  getAllRoles,
  // deleteAdminAction,
  editAdminInfo,
} from "../../reduxStore/action/action.creator";
import { Loader } from "../../components/Accessories";
import AdminModal from "./AdminModal";
import { setAdmin, toggleAdmin, changeAdminPassword } from "../../API/UserRequest";
import AdminEditModal from "./AdminEditModal";
import PasswordChangeModal from "./PasswordChangeModal"

class AllAdmin extends React.Component {
  state = {
    currentPage: 1,
    display: false,
    display2: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.success == true) {
      this.setState({
        display2: false,
      });
      this.props.getAdmins(this.props.merchant_data.id, this.props.token);
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.success != this.props.success) {
  //     alert("good");
  //   }
  // }

  componentDidMount() {
    this.props.getAdmins(this.props.token);
    this.props.getAllRoles(this.props.token); 
  }

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  handleNextPage = (props) => {
    const { index } = props;
    this.props.detailsInfo(props);
    this.props.history.push(`${this.props.match.url}/${index}/details`);
  };

  addNew = () => {
    this.setState({
      display: true,
    });
  };

  handleSubmit = async (data) => {
    try {
      const res = await setAdmin(data, this.props.token);
      this.props.getAdmins(this.props.merchant_data.id, this.props.token);
    } catch (error) {}
  };

  closeModal = () => {
    this.setState({
      display: false,
      display2: false,
    });
  };

  deleteAdmin = (id) => {
    this.props.deleteAdminAction(this.props.token, id);
    this.props.getAdmins(this.props.token, this.state.currentPage);
  };
  
  toggleHandler = async (id, lock) => {
    try {
      let action = "";
      if (lock == "0") {
        action = "disable";
      } else {
        action = "enable";
      }
      const res = await toggleAdmin(id, action, this.props.token);
      this.props.getAdmins(this.props.token, this.state.currentPage);
    } catch (error) {}
  };

  updateAdmin = (props) => {
    this.setState({
      ...this.state,
      display2: true,
      adminToEdit: props.id,
      adminData: props,
    });
  };

  // handleEdit = (data) => {
  //   this.props.editAdminInfo(this.state.adminToEdit, data, this.props.token);
  // };

  handlePasswordChange = async (data) => {
    const {result} = await changeAdminPassword(data, this.props.token);
    // if(result.success == false) {
    //   toast(`${result.response_message}`);
    // }
    // if(result.success == 'true') {
      toast(`${result.response_message}`)
        this.props.getAdmins(this.props.merchant_data.id, this.props.token);
        // this.closeModal()
    // }
  }

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <TableHead admin="true" />
          <tbody>
            {this.props.admins.map((info, index) => (
              <Row
                {...info}
                admin="true"
                index={this.getIndex(index + 1)}
                // goToDetails={this.handleNextPage}
                // deleteAdmin={this.deleteAdmin}
                // toggleHandler={this.toggleHandler}
                updateAdmin={this.updateAdmin}
              />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="All Admins"
              getInfo={this.props.getAdmins}
              addNew={this.addNew}
            />

            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">All Admins</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminModal
          display={this.state.display}
          closeModal={this.closeModal}
          handleSubmit={this.handleSubmit}
          // adminRoles={this.props.getAdmins}
        />
        {/* <AdminEditModal
          display={this.state.display2}
          closeModal={this.closeModal}
          adminData={this.state.adminData}
          handleEdit={this.handleEdit}
        /> */}
        <PasswordChangeModal 
          display={this.state.display2}
          closeModal={this.closeModal}
          adminData={this.state.adminData}
          handlePasswordChange={this.handlePasswordChange}
        />
        <ToastContainer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.users.alladmins.loading,
  admins: state.users.alladmins.adminData,
  success: state.users.alladmins.update_message,
  total: state.users.alladmins.total,
  err: state.users.alladmins.failed,
  token: state.login.token,
  merchant_data: state.login.data
});
export default connect(mapStateToProps, {
  getAdmins,
  getAllRoles,
  // detailsInfo,
  // deleteAdminAction,
  editAdminInfo,
})(AllAdmin);
