import { headers } from "./api.config";

// export const InvestmentReportRequest = async (id, status, date, token) => {
//   // alert(`${process.env.REACT_APP_BASE_URL}/savinvest/investment_analysis/merchant_users/${id}`)
//   const response = await fetch(
//     `${process.env.REACT_APP_BASE_URL}/savinvest/investment_analysis/merchant_users/${id}`,
//     {
//       headers: headers(token),
//     }
//   );

//   if (response.ok) {
//     const { data } = await response.json();
//     // console.log('investmentReportData', data);
//     investmentReportData = data
//     return { investmentReportData };
//   }
// };

export const fetchInvestmentReportData = async (url_path, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url_path}`, {
    headers: headers(token),
  });
  if (response.ok) {
    const { data, success } = await response.json();
    console.log('new invest report data', data);
    return { success, data };
  }
};


