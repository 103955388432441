import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../components/Accessories";

class InvestmentProductModal extends React.Component {
  state = {
    name: "",
    description: "",
    recurring: false,
    status: "in-active",
    created_by: this.props.merchant_data.id,
    merchant_id: this.props.merchant_data.id,
    return_type: "",
    approval_date: "",
    image_url: "url",
    category_id: "",
    cycle_name: "",
    ROI_percentage: "",
    total_investment_sum: "",
    slot: "",
    cost_per_slot: "",
    minimum_slot: "",
    start_date: "",
    end_date: "",
    product_curl: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    this.props.addProduct(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Create Investment Product">
        <div className="body-grid">
          <div className="box box1">
            <label>Product Name</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>

          <div className="box box2">
            <label>Product Description</label>
            <input
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
            />
          </div>

          <div className="box box1">
            <label>Investment Category</label>
            <select
              name="category_id"
              className="form-control"
              value={this.state.category_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.invest_category &&
                this.props.invest_category.map((content) => (
                  <option value={content.id}>{content.name}</option>
                ))}
            </select>
          </div>

          <div className="box box2">
            <label>Return Type</label>
            <select
              className="form-control"
              name="return_type"
              value={this.state.return_type}
              onChange={this.handleChange}>
              <option></option>
              <option value="fixed">Fixed</option>
              <option value="percentage">Percentage</option>
            </select>
          </div>
          
          <div className="box box1">
            <label>Investment Start Date</label>
            <input
              type="date"
              className="form-control"
              name="start_date"
              value={this.state.start_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Investment End Date</label>
            <input
              type="date"
              className="form-control"
              name="end_date"
              value={this.state.end_date}
              onChange={this.handleChange}
            />
          </div>
          
          <div className="box box1">
            <label>Name of Investment Cycle</label>
            <input
              className="form-control"
              name="cycle_name"
              value={this.state.cycle_name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Total Investment Sum</label>
            <input
              className="form-control"
              name="total_investment_sum"
              value={this.state.total_investment_sum}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>ROI percentage</label>
            <input
              className="form-control"
              name="ROI_percentage"
              value={this.state.ROI_percentage}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Total Expected Slot For Investment Kick Off</label>
            <input
              className="form-control"
              name="slot"
              value={this.state.slot}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Minimum Slot To Be Bought By User</label>
            <input
              className="form-control"
              name="minimum_slot"
              value={this.state.minimum_slot}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Cost Per Slot</label>
            <input
              className="form-control"
              name="cost_per_slot"
              value={this.state.cost_per_slot}
              onChange={this.handleChange}
            />
          </div>

          {/* <div className="box box9">
            <label>Product Call Back URL</label>
            <input
              className="form-control"
              name="product_curl"
              value={this.state.product_curl}
              onChange={this.handleChange}
            />
          </div> */}
          
          <div className="box box1">
            <div className="row">
              <div className="col-md-12">
                <label>Re occuring </label>
              </div>
            </div>

            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="recurring"
                checked={this.state.recurring}
                onChange={this.handleCheckBox}
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-accent"
                onClick={this.handleSubmit}>
                Set Investment Product
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export const UploadModal = (props) => {
  return (
    <Modal
      display={props.display ? "block" : "none"}
      closeModal={props.closeModal}
      modalTitle="Upload Product Image">
      <div className="body-grid">
        <div className="box box9">
          <label>Choose Investment Product</label>
          <select
            className="form-control"
            name="product_id"
            value={props.product_id}
            onChange={props.handleChange}>
            <option></option>
            {props.invest_prod &&
              props.invest_prod.map((content) => (
                <option value={content.id}>{content.name}</option>
              ))}
          </select>
        </div>
        <div className="box box9">
          <label>Upload Image</label>
          <input
            className="form-control"
            type="file"
            name="image"
            accept="image/png, image/jpeg"
            onChange={props.handleFile}
          />
        </div>
        <div className="box box9">
          <div className="text-center">
            <br/>
            <button
              type="button"
              class="btn btn-accent"
              onClick={props.handleSubmit}>
              Upload Image
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  token: state.login.token,
  merchant_data: state.login.data,
  // admins: state.users.alladmins.adminData,
  // merchant_data: state.invest.getData.merchant_data,
  invest_category: state.products.allProducts.invest_category,
});

export default connect(mapStateToProps, {})(InvestmentProductModal);
