import React from 'react';
import { connect } from 'react-redux';
import SideBar from '../../components/SideBar';
import DashboardNavBar from '../../components/DashboardNavbar';
import { ProductsTableHead, ProductsRow } from '../../components/Rows';
import { callBackMethod, Loader, SingleCard, DateRange } from '../../components/Accessories';
import ProductModal, { UploadModal } from './ProductModal';
import ProductEditModal from './ProductEditModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../morestyles.css';
import {
	// getProducts,
	getInvestmentCategory,
	addInvestProduct,
	uploadProductImage,
	editInvestmentProduct,
	getInvestmentProduct,
	filterInvestment,
} from '../../reduxStore/action/action.creator';

class AllProducts extends React.Component {
	state = {
		currentPage: 1,
		display: false,
		// display2: false,
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.success == true) {
			this.setState({
				display2: false,
			});
			this.props.getProducts(this.props.id, this.props.token); // trying to pass down the merchant_id here along side token
		}
	}

	componentDidMount() {
		// this.props.getProducts(this.props.merchant_data.id, this.props.token);

		this.props.getInvestmentProduct(
			`/savinvest/merchant/investment_products/${this.props.merchant_data.id}`,
			this.props.token
		);

		this.props.getInvestmentCategory('/savinvest/investment_categories', this.props.token);
	}

	getIndex = (index) => {
		const newIndex = 15 * (this.state.currentPage - 1) + index;
		return newIndex;
	};

	handleNextPage = (props) => {
		const { index } = props;
		this.props.detailsInfo(props);
		this.props.history.push(`${this.props.match.url}/${index}/details`);
	};

	addNew = () => {
		this.setState({
			display: true,
		});
	};

	// For Modals

	showModal = (e) => {
		e.preventDefault();
		this.setState({
			display: true,
		});
	};

	closeModal = () => {
		this.setState({
			display: false,
			display2: false,
			upload: false,
			edit_modal: false,
			detail_modal: false,
		});
	};

	appoveModal = (e) => {
		e.preventDefault();
		this.setState({
			...this.state,
			upload: true,
		});
	};

  notify = () => toast(`Success - ${this.props.invest_product_resp}`)

	////////////////////////

	// Uploading product images
	handleSubmit = () => {
		const formData = new FormData();
		formData.append('name', this.state.name);
		formData.append('image', this.state.image);

		callBackMethod(this.props.uploadProductImage(this.state.product, formData, this.props.token))
			.then(() => this.setState({ ...this.state, upload: false }))
			.then(() => alert(JSON.stringify(this.props.upload_resp.response_message)));
	};

	handleFile = (e) => {
		this.setState({
			image: e.target.files[0],
			file: URL.createObjectURL(e.target.files[0]),
		});
	};

	handleChange = (e) => {
		this.setState({
			product: e.target.value,
			name: 'item name',
		});
	};

	editProd = (props) => {
		this.setState({
			...this.state,
			edit_modal: true,
			editData: props,
		});
	};

	// Add investment product call

	addProduct = (data) => {
		callBackMethod(this.props.addInvestProduct('/savinvest/investment_products', data, this.props.token))
			.then(() => this.setState({ display: false }))
			.then(() => {
				this.closeModal();
        this.notify();
			})
			.then(() =>
				this.props.getInvestmentProduct(
					`/savinvest/merchant/investment_products/${this.props.merchant_data.id}`,
					this.props.token
				)
			);
	};

	/// Editing products logic

	editProduct = (data) => {
		callBackMethod(
			this.props.editInvestmentProduct(
				`/savinvest/investment_products/${this.state.editData.id}`,
				data,
				this.props.token,
				'PUT'
			)
		)
			.then(() => this.setState({ edit_modal: false }))
			.then(() => {
				alert(this.props.edit_prod_resp);
			})
			.then(() => this.props.getProducts(this.props.merchant_data.id, this.props.token));
	};

	// for filter boxes

	filterByType = (e) => {
		callBackMethod(
			this.setState({
				filterby: e.target.value,
			})
		).then(() => this.runByType(this.state.filterby));
	};

	runByType = (type) => {
		if (type == 'status') {
			this.setState({
				showDropStatus: true,
				showBtnDate: false,
			});
		} else {
			this.setState({
				showBtnDate: true,
				showDropStatus: false,
			});
		}
	};

	handleRange = (event, picker) => {
		let start = new Date(picker.startDate).toISOString();
		let end = new Date(picker.endDate).toISOString();

		start = start.substring(0, start.indexOf('T'));
		end = end.substring(0, end.indexOf('T'));

		callBackMethod(
			this.setState({
				startDate: start,
				endDate: end,
			})
		).then(() =>
			this.props.filterInvestment(
				'date',
				`/savinvest/merchant/investment_products/${this.props.merchant_data.id}?from=${this.state.startDate}&to=${this.state.endDate}`,
				this.props.token
			)
		);
	};

	handleStatusFilter = (e) => {
		callBackMethod(
			this.setState({
				status: e.target.value,
			})
		).then(() =>
			this.props.filterInvestment(
				'status',
				`/savinvest/merchant/investment_products/${this.props.merchant_data.id}?status=${this.state.status}`,
				this.props.token
			)
		);
	};

	// Filter boxes logic end

	render() {
		let itemInTable;
		if (this.props.loading) {
			itemInTable = <Loader loading={this.props.loading} />;
		} else {
			itemInTable = (
				<>
					<ProductsTableHead admin='true' />
					<tbody>
						{this.props.products?.map((info, index) => (
							<ProductsRow
								{...info}
								// admin="true"
								index={this.getIndex(index + 1)}
								editProduct={this.editProd}
								// goToDetails={this.handleNextPage}
								// deleteAdmin={this.deleteAdmin}
								// toggleHandler={this.toggleHandler}
								// updateAdmin={this.updateAdmin}
							/>
						))}
					</tbody>
				</>
			);
		}
		return (
			<>
				<div>
					<SideBar />
					<div class='main-content' id='panel'>
						<DashboardNavBar submenu='All Products' getInfo={this.props.getProducts} addNew={this.addNew} />

						<div class='container-fluid mt--6'>
							<div class='row'>
								<div class='col'>
									<div class='card'>
										<div class='card-header inline-space'>
											<h3 class='mb-0'>Products</h3>

											<div className='row'>
												<div className='col-12'>
													<div className='col-12 text-left'>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<select
																value={this.state.filterby}
																className='form-control'
																style={{ width: '20em' }}
																onChange={this.filterByType}
															>
																<option>filter by...</option>
																<option value='status'>filter by status</option>
																<option value='date'>filter by date</option>
															</select>

															<div style={{ paddingLeft: '1em' }}>
																{this.state.showBtnDate && <DateRange handleRange={this.handleRange} />}

																{this.state.showDropStatus && (
																	<select
																		style={{ width: '15em' }}
																		onChange={this.handleStatusFilter}
																		className='form-control'
																	>
																		<option>Filter by...</option>
																		<option value='active'>Active</option>
																		<option value='in-active'>In-Active</option>
																		<option value='pending'>Pending</option>
																	</select>
																)}
															</div>
														</div>
													</div>
													{/* filter buttons here */}
												</div>
											</div>
											<div class='col-lg-6 col-5 text-right'>
												<a href='#' class='btn btn-sm btn-neutral' onClick={this.showModal}>
													<i class='fas fa-plus'></i> Add New Product
												</a>
												<a href='#' class='btn btn-sm btn-neutral' onClick={this.appoveModal}>
													<i class='fas fa-plus'></i> Add Product Image
												</a>
											</div>
										</div>
										<div className='table-responsive py-4'>
											<table className='table table-flush' id='datatable-basic' style={{ marginBottom: '150px' }}>
												{itemInTable}
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ProductModal display={this.state.display} closeModal={this.closeModal} addProduct={this.addProduct} />
				<UploadModal
					display={this.state.upload}
					closeModal={this.closeModal}
					invest_prod={this.props.products}
					handleChange={this.handleChange}
					handleFile={this.handleFile}
					handleSubmit={this.handleSubmit}
				/>
				<ProductEditModal
					display={this.state.edit_modal}
					editData={this.state.editData}
					closeModal={this.closeModal}
					editProduct={this.editProduct}
				/>
        <ToastContainer />
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.products.allProducts.loading,
	invest_product_resp: state.products.setData.invest_product_resp,
	products: state.products.allProducts.invest_prod,
	token: state.login.token,
	total: state.products.allProducts.total,
	err: state.products.allProducts.failed,
	merchant_data: state.login.data,
	upload_resp: state.products.setData.upload_resp,
});

export default connect(mapStateToProps, {
	// getProducts,
	getInvestmentProduct,
	filterInvestment,
	getInvestmentCategory,
	addInvestProduct,
	uploadProductImage,
	editInvestmentProduct,
})(AllProducts);
