import { headers } from "../api.config";

export const setRole = async (data, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/roles`, {
    method: "POST",
    headers: headers(token),
    body: JSON.stringify(data),
  });

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};

export const getRole = async (data, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/roles`, {
    headers: headers(token),
  });

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};
