import React from "react";
import { Modal } from "../../components/Accessories";
import { connect } from "react-redux";
import { getAdminRole } from "../../reduxStore/action/action.creator";

class PasswordChangeModal extends React.Component {
  state = {
    old_password: "",
    new_password: "",
    c_new_password: "",
    merchant_id: ""
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.adminData != prevProps.adminData) {
      this.setState({
        old_password: "",
        new_password: "",
        c_new_password: "",
        merchant_id: this.props.adminData.id

      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Change Admin  Password">
        <p style={{textAlign: 'center', fontSize: 'small'}}>Admin Email: {this.props.adminData?.email}</p>
        <div className="body-grid">
          <div className="box box9">
            <label>Old Password</label>
            <div className="input-div">
              <input
                type="text"
                name="old_password"
                value={this.state.old_password}
                placeholder="Enter Old Password"
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="box box1">
            <label for="fee-name">New Password</label>

            <div className="input-div">
              <input
                type="text"
                name="new_password"
                value={this.state.new_password}
                placeholder="Enter New Password"
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>Confirm New Password</label>

            <div className="input-div">
              <input
                name="c_new_password"
                value={this.state.c_new_password}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <br />
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-accent"
                onClick={() => this.props.handlePasswordChange(this.state)}>
                Change Password
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  // data: state.roles.getrole.data,
});

export default connect(mapStateToProps, { getAdminRole })(PasswordChangeModal);
