import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  USER_SUCCESS,
  USER_FAILED,
  ADMIN_SUCCESS,
  ADMIN_FAILED,
  GET_INVEST_PRODUCT,
  INVESTMENT_PRODUCT_SUCCESS,
  INVESTMENT_PRODUCT_FAILED,
  PRODUCT_SUBSCRIPTION_SUCCESS,
  PRODUCT_SUBSCRIPTION_FAILED,
  LOGOUT,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAILED,
  INVESTMENT_RETURNS_FAILED,
  INVESTMENT_RETURNS_SUCCESS,
  GET_INVEST_CATEGORY,
  ADD_INVEST_PRODUCT,

  EDIT_INVEST_PROD,
  EDIT_INVEST_PROD_ERROR,
  UPLOAD_IMG,

  DETAIL_INFO,
  SET_ROLE,
  GET_ROLE,
  SET_PERMISSION,
  GET_PERMISSION,
  SET_PERMISSION_MODULE,
  GET_PERMISSION_MODULE,
  SET_ADMIN_PERMISSION,
  GET_ADMIN_PERMISSION,
  DELETE_PERMISSION_LIST,

  ENABLE_USER,
  DISABLE_USER,
  UPDATE_ADMIN,
  ROLES,
  INVESTMENT_PROGRESS,
  ADD_INVESTMENT_PROGRESS,
  FILTER_INVESTMENT_BY_STATUS,
  FILTER_INVESTMENT_BY_DATE,
  GET_INVESTMENT_REPORT,
  
} from "./action.types";
import {
  loginAdmin,
  fetchAdmin,
  fetchUsers,

  setRole,
  getRole,
  setpermissionRequest,
  getpermissionRequest,
  setPermissionModule,
  getPermissionModule,
  setAdminPermission,
  getAllAdminPermission,
  deleteAuthPermission,
  lockUser,
  editAdmin,
  filterUsers,

  fetchProducts,
  fetchProductSubscriptions,
  fetchProductDetails,
  fetchProductPaymentReturns,
  fetchInvestmentData,
  fetchInvestmentReportData,
  addInvestmentData,
  uploadImage,
  adminRoles,
  fetchInvestmentProgressHistory
} from "../../API";

export const adminLogin = (mData) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { success, token, permission, data } = await loginAdmin(mData);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { success, token, permission, data },
    });
  } catch (error) {
    alert(error);
    dispatch({ type: LOGIN_FAIL, payload: error });
  }
};

export const getUsers = (token, pageNum) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { success, users, total } = await fetchUsers(token, pageNum);
    dispatch({ type: USER_SUCCESS, payload: { success, users, total } });
  } catch (error) {
    dispatch({ type: USER_FAILED, payload: error });
  }
};

export const getAdmins = (token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { admin_info, success, total } = await fetchAdmin(token);
    console.log(admin_info);
    dispatch({ type: ADMIN_SUCCESS, payload: { success, admin_info, total } });
  } catch (error) {
    dispatch({ type: ADMIN_FAILED, payload: error });
  }
};

// still used by manage-progress page
export const getProducts = (id, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { product_info, success, total } = await fetchProducts(id, token);
    // console.log(product_info);
    dispatch({ type: INVESTMENT_PRODUCT_SUCCESS, payload: { success, product_info, total } });
  } catch (error) {
    dispatch({ type: INVESTMENT_PRODUCT_FAILED, payload: error });
  }
};

export const getInvestmentProduct = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    console.log('investment products data from action', data.data);
    dispatch({ type: GET_INVEST_PRODUCT, payload: { data } });
  } catch (error) {}
};

export const filterInvestment = (type, url, token) => async (dispatch) => {
  let action_type;
  if (type == "date") {
    action_type = FILTER_INVESTMENT_BY_DATE;
  } else {
    action_type = FILTER_INVESTMENT_BY_STATUS;
  }
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: action_type, payload: { data } });
  } catch (error) {}
};

export const getProductDetails = (id, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { product_data, success, total } = await fetchProductDetails(id, token);
    console.log(product_data);
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: { success, product_data, total } });
  } catch (error) {
    dispatch({ type: PRODUCT_DETAILS_FAILED, payload: error });
  }
};

export const getProductSubscriptions = (id, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { product_subscriptions, success, total } = await fetchProductSubscriptions(id, token);
    console.log(product_subscriptions);
    dispatch({ type: PRODUCT_SUBSCRIPTION_SUCCESS, payload: { success, product_subscriptions, total } });
  } catch (error) {
    dispatch({ type: PRODUCT_SUBSCRIPTION_FAILED, payload: error });
  }
};

export const getProgressHistory = (id, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { investment_progress, success} = await fetchInvestmentProgressHistory(id, token);
    console.log(investment_progress);
    dispatch({ type: INVESTMENT_PROGRESS, payload: { success, investment_progress } });
  } catch (error) {}
};

export const getInvestmentReturns = (id, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { investment_returns, success, total } = await fetchProductPaymentReturns(id, token);
    console.log(investment_returns);
    dispatch({ type: INVESTMENT_RETURNS_SUCCESS, payload: { success, investment_returns, total } });
  } catch (error) {
    dispatch({ type: INVESTMENT_RETURNS_FAILED, payload: error });
  }
};

export const addInvestProduct = (url, mData, token) => async (dispatch) => {
  try {
    const { success } = await addInvestmentData(url, mData, token);
    dispatch({ type: ADD_INVEST_PRODUCT, payload: { success } });
  } catch (error) {}
};

export const addProgressHistory = (url, mData, token) => async (dispatch) => {
  try {
    const { success } = await addInvestmentData(url, mData, token);
    dispatch({ type: ADD_INVESTMENT_PROGRESS, payload: { success } });
  } catch (error) {}
};

export const getInvestmentCategory = (url, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    console.log('investment category data from action', data);
    dispatch({ type: GET_INVEST_CATEGORY, payload: { data } });
  } catch (error) {}
};

export const uploadProductImage = (id, form_data, token) => async (
  dispatch
) => {
  try {
    const { data } = await uploadImage(id, form_data, token);
    console.log('response_message from upload image action creator', data )
    dispatch({ type: UPLOAD_IMG, payload: { data } });
  } catch (error) {}
};

export const filterInvestmentReport = (type, url, token) => async (dispatch) => {
  let action_type;
  if (type == "date") {
    action_type = FILTER_INVESTMENT_BY_DATE;
  } else {
    action_type = FILTER_INVESTMENT_BY_STATUS;
  }
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentReportData(url, token);
    dispatch({ type: action_type, payload: { data } });
  } catch (error) {}
};

export const getInvestmentProductReport = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentReportData(url, token);
    dispatch({ type: GET_INVESTMENT_REPORT, payload: { data } });
  } catch (error) {}
};

export const editInvestmentProduct = (url, mData, token, type) => async (
  dispatch
) => {
  try {
    const { response_message } = await addInvestmentData(
      url,
      mData,
      token,
      type
    );
    dispatch({ type: EDIT_INVEST_PROD, payload: { response_message } });
  } catch (error) {
    const errMessage = error.message;
    dispatch({ type: EDIT_INVEST_PROD_ERROR, payload: { errMessage } });
  }
};

export const logOut = () => ({
  type: LOGOUT,
  payload: true,
});

export const AppStart = () => ({
  type: "APP_START",
  payload: false,
});

export const setAdminRole = (mData, token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await setRole(mData, token);
    dispatch({ type: SET_ROLE, payload: { success, data, response_message } });
  } catch (error) {}
};

export const getAdminRole = (token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await getRole(token);
    dispatch({ type: GET_ROLE, payload: { success, data, response_message } });
  } catch (error) {}
};

export const getAllRoles = (token) => async (dispatch) => {
  try {
    const { admin_roles, success } = await adminRoles(token);
    dispatch({ type: ROLES, payload: { admin_roles, success } });
  } catch (error) {}
};

export const setPermission = (mData, token) => async (dispatch) => {
  try {
    const { success, response_message } = await setpermissionRequest(
      mData,
      token
    );
    dispatch({ type: SET_PERMISSION, payload: { success, response_message } });
  } catch (error) {}
};

export const getPermission = (token) => async (dispatch) => {
  try {
    const { success, data } = await getpermissionRequest(token);
    dispatch({ type: GET_PERMISSION, payload: { success, data } });
  } catch (error) {}
};

export const setPermissionMod = (mData, token) => async (dispatch) => {
  try {
    const { success, response_message } = await setPermissionModule(
      mData,
      token
    );
    dispatch({
      type: SET_PERMISSION_MODULE,
      payload: { success, response_message },
    });
  } catch (error) {}
};

export const getPermissionMod = (token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await getPermissionModule(
      token
    );
    dispatch({
      type: GET_PERMISSION_MODULE,
      payload: { success, data, response_message },
    });
  } catch (error) {}
};

export const sendAdminPermission = (data, token) => async (dispatch) => {
  try {
    const { success, response_message } = await setAdminPermission(data, token);
    dispatch({
      type: SET_ADMIN_PERMISSION,
      payload: { success, response_message },
    });
  } catch (error) {}
};

export const getAdminPermission = (token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await getAllAdminPermission(
      token
    );
    dispatch({
      type: GET_ADMIN_PERMISSION,
      payload: { success, data, response_message },
    });
  } catch (error) {}
};

export const deletePermission = (url, token) => async (dispatch) => {
  try {
    const result = await deleteAuthPermission(url, token);
    dispatch({ type: DELETE_PERMISSION_LIST, payload: { result } });
  } catch (error) {}
};

// export const enableLock = (url, token) => async (dispatch) => {
//   try {
//     const { success, Response_message } = await lockUser(url, token);
//     dispatch({
//       type: ENABLE_USER,
//       payload: { success, Response_message },
//     });
//   } catch (error) {}
// };

// export const disableLock = (url, token) => async (dispatch) => {
//   try {
//     const { success, Response_message } = await lockUser(url, token);
//     dispatch({ type: DISABLE_USER, payload: { success, Response_message } });
//   } catch (error) {}
// };

export const editAdminInfo = (id, data, token) => async (dispatch) => {
  try {
    const { success, response_message } = await editAdmin(id, data, token);
    dispatch({ type: UPDATE_ADMIN, payload: { success, response_message } });
  } catch (error) {}
};



//// to use

// export const filterAllUsers = (data, token) => async (dispatch) => {
//   try {
//     const { success, users, total } = await filterUsers(data, token);
//     dispatch({ type: FILTER_USERS, payload: { success, users, total } });
//   } catch (error) {}
// };
