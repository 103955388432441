import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../components/Accessories";

class InvestmentEditModal extends React.Component {
  state = {
      name: "",
      description: "",
      recurring: "",
      status: "",
      created_by: "",
      merchant_id: "",
      return_type: "",
      approval_date: "",
      image_url: "url",
      category_id: "",
      cycle_name: "",
      ROI_percentage: "",
      total_investment_sum: "",
      slot: "",
      cost_per_slot: "",
      minimum_slot: "",
      start_date: "",
      end_date: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.editData != prevProps.editData) {
      this.setState({
        name: this.props.editData.name,
        description: this.props.editData.description,
        recurring: this.props.editData.recurring,
        status: this.props.editData.status,
        approved_by: this.props.editData.approved_by,
        created_by: this.props.editData.created_by,
        merchant_id: this.props.editData.merchant_id,
        return_type: this.props.editData.return_type,
        created_date: this.props.editData.created_date,
        approval_date: this.props.editData.approval_date,
        investment_account_number: this.props.editData
          .investment_account_number,
        product_ref: this.props.editData.product_ref,
        image_url: this.props.editData.image_url,
        category_id: this.props.editData.category_id,
        cycle_name: this.props.editData.investment_cycle.cycle_name,
        ROI_percentage: this.props.editData.investment_cycle.ROI_percentage,
        total_investment_sum: this.props.editData.investment_cycle
          .total_investment_sum,
        slot: this.props.editData.investment_cycle.slot,
        cost_per_slot: this.props.editData.investment_cycle.cost_per_slot,
        minimum_slot: this.props.editData.investment_cycle.minimum_slot,
        start_date: this.props.editData.start_date,
        end_date: this.props.editData.end_date,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    this.props.editProduct(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Edit Investment Product">
        <div className="body-grid">
          <div className="box box1">
            <label>Name</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Description</label>
            <input
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Status</label>
            <select
              className="form-control"
              name="status"
              value={this.state.status}
              onChange={this.handleChange}>
              <option></option>
              <option value="active">Active</option>
              <option value="pending">Pending</option>
            </select>
          </div>
          <div className="box box2">
            <label>Return Type</label>
            <select
              className="form-control"
              name="return_type"
              value={this.state.return_type}
              onChange={this.handleChange}>
              <option></option>
              <option value="fixed">Fixed</option>
              <option value="percentage">Percentage</option>
            </select>
          </div>
          <div className="box box1">
            <label>Created Date</label>
            <input
              type="date"
              className="form-control"
              name="created_date"
              value={this.state.created_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Approval Date</label>
            <input
              type="date"
              className="form-control"
              name="approval_date"
              value={this.state.approval_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Start Date</label>
            <input
              type="date"
              className="form-control"
              name="start_date"
              value={this.state.start_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>End Date</label>
            <input
              type="date"
              className="form-control"
              name="end_date"
              value={this.state.end_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Investment Account Number</label>
            <input
              className="form-control"
              name="investment_account_number"
              value={this.state.investment_account_number}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Product Reference</label>
            <input
              className="form-control"
              name="product_ref"
              value={this.state.product_ref}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Category</label>
            <select
              name="category_id"
              className="form-control"
              value={this.state.category_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.invest_category &&
                this.props.invest_category.map((content) => (
                  <option value={content.id}>{content.name}</option>
                ))}
            </select>
          </div>
          <div className="box box2">
            <label>Cycle Name</label>
            <input
              className="form-control"
              name="cycle_name"
              value={this.state.cycle_name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>ROI percentage</label>
            <input
              className="form-control"
              name="ROI_percentage"
              value={this.state.ROI_percentage}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Total Investment Sum</label>
            <input
              className="form-control"
              name="total_investment_sum"
              value={this.state.total_investment_sum}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Slot</label>
            <input
              className="form-control"
              name="slot"
              value={this.state.slot}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Cost Per Slot</label>
            <input
              className="form-control"
              name="cost_per_slot"
              value={this.state.cost_per_slot}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Minimum Slot</label>
            <input
              className="form-control"
              name="minimum_slot"
              value={this.state.minimum_slot}
              onChange={this.handleChange}
            />
          </div>

          <div className="box box1">
            <div className="row">
              <div className="col-md-12">
                <label>Re occuring </label>
              </div>
            </div>

            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="recurring"
                checked={this.state.recurring}
                onChange={this.handleCheckBox}
                data-onstyle="outline-warning"
              />
              <span class="custom-toggle-slider rounded-circle" data-onstyle="outline-warning"></span>
            </label>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-accent"
                onClick={this.handleSubmit}>
                Edit Investment Product
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  merchant_data: state.login.data,
  invest_category: state.products.allProducts.invest_category,
});

export default connect(mapStateToProps, {})(InvestmentEditModal);
