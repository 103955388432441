import {
  INVESTMENT_PRODUCT_FAILED,
  INVESTMENT_PRODUCT_SUCCESS,
  PRODUCT_SUBSCRIPTION_FAILED,
  PRODUCT_SUBSCRIPTION_SUCCESS,
  PRODUCT_DETAILS_FAILED,
  PRODUCT_DETAILS_SUCCESS,
  INVESTMENT_RETURNS_FAILED,
  INVESTMENT_RETURNS_SUCCESS,
  GET_INVEST_CATEGORY,
  ADD_INVEST_PRODUCT,
  UPLOAD_IMG,
  EDIT_INVEST_PROD,
  EDIT_INVEST_PROD_ERROR,
  INVESTMENT_PROGRESS,
  GET_INVEST_PRODUCT,
  FILTER_INVESTMENT_BY_DATE,
  FILTER_INVESTMENT_BY_STATUS,
  GET_INVESTMENT_REPORT
} from "../action/action.types";

import { combineReducers } from "redux";

const initialState = {
  productsData: [],
  productDetailsData: [],
  investmentReturnsData: [],
  productSubsData: [],
  progressHistoryData: [],
  total: 0,
  response_msg: "",
};

// using as central getter
const allProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
        update_message: "",
      };
    case INVESTMENT_PRODUCT_SUCCESS:
      return {
        ...state,
        productsData: action.payload.product_info,
        total: action.payload.total,
        loading: false,
      };
    case INVESTMENT_PRODUCT_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
      case GET_INVEST_CATEGORY:
      return {
        ...state,
        loading: false,
        invest_category: action.payload.data,
      };
      // case GET_INVESTMENT_REPORT:
      // return {
      //   ...state,
      //   loading: false,
      //   filtering: false,
      //   invest_report: action.payload.data,
      // };
      case GET_INVEST_PRODUCT:
      return {
        ...state,
        loading: false,
        filtering: false,
        invest_prod: action.payload.data.data
      };
    case FILTER_INVESTMENT_BY_DATE:
      return {
        ...state,
        loading: false,
        filtering: true,
        invest_prod: action.payload.data.data,
      };
    case FILTER_INVESTMENT_BY_STATUS:
      return {
        ...state,
        loading: false,
        filtering: true,
        invest_prod: action.payload.data.data,
      };
    default:
      return state;
  }
};

// using as central setter
const setInvestmentReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_INVEST_PRODUCT:
      return {
        ...state,
        invest_product_resp: action.payload.success,
      };
    case UPLOAD_IMG:
      return {
        ...state,
        upload_resp: action.payload.data,
      };
    case EDIT_INVEST_PROD:
      return {
        ...state,
        edit_prod_resp: action.payload.response_message,
      };
    case EDIT_INVEST_PROD_ERROR:
      return {
        ...state,
        edit_prod_resp: action.payload.errMessage,
      };
    default:
      return state;
  }
};

const productDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetailsData: action.payload.product_data,
        total: action.payload.total,
        loading: false,
      };
    case PRODUCT_DETAILS_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    default:
      return state;
  }
};

const productSubscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
        update_message: "",
      };
    case PRODUCT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        productSubsData: action.payload.product_subscriptions,
        total: action.payload.total,
        loading: false,
      };
    case PRODUCT_SUBSCRIPTION_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    default:
      return state;
  }
};

const investmentProgressHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
        update_message: "",
      };
    case INVESTMENT_PROGRESS:
      return {
        ...state,
        progressHistoryData: action.payload.investment_progress,
        loading: false,
      };
    default:
      return state;
  }
};

const investmentReturnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
        // update_message: "",
      };
    case INVESTMENT_RETURNS_SUCCESS:
      return {
        ...state,
        investmentReturnsData: action.payload.investment_returns,
        total: action.payload.total,
        loading: false,
      };
    case INVESTMENT_RETURNS_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    default:
      return state;
  }
};

export const productsReducer = combineReducers({
  allProducts: allProductsReducer,
  productSubscriptions: productSubscriptionsReducer,
  productDetails: productDetailsReducer,
  investmentReturns: investmentReturnsReducer,
  setData: setInvestmentReducer,
  investmentProgress: investmentProgressHistoryReducer
});
