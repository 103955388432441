import React from 'react';
import { connect } from 'react-redux';
import SideBar from '../../components/SideBar';
import DashboardNavBar from '../../components/DashboardNavbar';
import { ProductDetailsList } from '../../components/Rows';
// import { ProductSubscriptionsTableHead, ProductSubscriptionsRow } from "../../components/Rows";

import { Modal, Loader } from '../../components/Accessories';
import { Link } from 'react-router-dom';
import { getProductDetails, getProductSubscriptions } from '../../reduxStore/action/action.creator';

class ProductDetails extends React.Component {
	state = {
		isTabActive: [],
	};

	componentDidMount() {
		let urlParam = this.props.history.location.pathname;
		urlParam = urlParam.substring(urlParam.indexOf('s/') + 2, urlParam.indexOf('/d'));

		this.props.getProductDetails(urlParam, this.props.token);

		this.setState({
			...this.state,
		});

    this.props.getProductSubscriptions(urlParam, this.props.token);

		let activeTab = [];
		activeTab[1] = true;
		this.setState({
			isTabActive: activeTab,
		});
	}

	changeTabActive = (id) => {
		let activeTab = [];
		activeTab[id] = true;
		this.setState((prevState) => ({
			isTabActive: activeTab,
		}));
	};

	closeModal = () => {
		this.setState({
			display: false,
		});
	};

	showModal = (e) => {
		e.preventDefault();
		this.setState({
			display: true,
		});
	};

	goBack = () => {
		this.props.history.goBack();
  };
  
  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

	render() {
		let itemInCard;
		const params = this.props.location;

		if (this.props.loading) {
      itemInCard = <Loader loading={this.props.loading} />;
		} else {
			itemInCard = (
				<div class='row' style={{ display: 'flex', borderRadius: '2em' }}>
					<div class='col-7 userDetailsCol1'>
						<div
							style={{
								width: '100%',
								overflowX: 'scroll',
							}}
						>
							{this.props?.productDetails && <ProductDetailsList {...this.props?.productDetails} />}
						</div>
					</div>
					<div class='col-5 userDetailsCol2'>
						{/* second tab here */}
						<div class='tab' role='tabpanel'>
							<div
								class='card-header'
								style={{
									margin: '0px',
									backgroundColor: 'lavenderblush',
									borderBottom: '0px',
								}}
							>
								<ul class='nav nav-tabs mt--3 ml--4' role='tablist'>
									<li
										role='presentation'
										className={this.state.isTabActive[1] ? 'active' : ''}
										onClick={() => this.changeTabActive(1)}
									>
										<a href='#Section1' aria-controls='wallets' role='tab' onClick={this.loadBalance} data-toggle='tab'>
											{/* <i class='fa fa-dollar'> &nbsp; </i> */}
                      Investment Subscriptions
										</a>
									</li>
									<li
										role='presentation'
										className={this.state.isTabActive[2] ? 'active' : ''}
										onClick={() => this.changeTabActive(2)}
									>
										<a href='#Section2' aria-controls='transactions' role='tab' data-toggle='tab'>
											{/* <i class='fa fa-credit-card'> &nbsp; </i> */}
											Investment Payment Returns
										</a>
									</li>
                </ul>
							</div>
							<div class='tab-content tabs'>
								<div role='tabpanel' class='tab-pane active' id='Section1'>
                  <table class="table table-bordered table-hover tableClass">
                    <thead>
                      <tr class="table-warning">
                        <th scope="col" style={{ fontWeight: "bold" }}>
                          S/N
                        </th>
                        <th scope="col" style={{ fontWeight: "bold" }}>
                          User ID
                        </th>
                        <th scope="col" style={{ fontWeight: "bold" }}>
                          Slots Taken
                        </th>
                        <th scope="col" style={{ fontWeight: "bold" }}>
                          Amount
                        </th>
                        <th scope="col" style={{ fontWeight: "bold" }}>
                          Status
                        </th>
                      </tr>
                    </thead>
										{/* {} */}
                    {this.props.productSubs[0].subscriptions?.map((props, index) => (
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{props.user_id}</td>
                          {/* <td> */}
                          <td>{props.slots}</td>
                          {/* </td> */}
                          <td>{props.amount}</td>
                          <td>{props.status}</td>
                        </tr>
                      </tbody>
                    ))                            
                      } 
                  </table>
                  
								</div>
								<div role='tabpanel' class='tab-pane fade' id='Section2'>
									<div className='table-responsive' style={{ maxHeight: '34em' }}>
										{/*  */}
									</div>
								</div>
              </div>
						</div>
					</div>
				</div>
			);
		}
		return (
			<>
				<div>
					<SideBar />
					<div className='main-content' id='panel'>
						<DashboardNavBar settings='false' />
						<div class='header pb-6' style={{ backgroundColor: '#f93' }}>
							<div class='container-fluid'>
								<div class='header-body'>
									<div class='row align-items-center py-4'>
										<div className='col-lg-6 col-7'>
											<span onClick={this.goBack} style={{ cursor: 'pointer' }}>
												<span
													style={{
														padding: '0 .5em 0 0',
														textAlign: 'center',
													}}
												>
													<i
														class='fas fa-chevron-left'
														style={{
															color: '#fff',
															padding: '0.3em .5em',
															border: '2px solid #fff',
															borderRadius: '50%',
															fontSize: '12px',
														}}
													></i>
												</span>
												<span style={{ color: '#fff' }}>Back</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class='container-fluid mt--5'>{itemInCard}</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	loading: state.products.productDetails.loading,
  productDetails: state.products.productDetails.productDetailsData,
  // loading: state.products.productSubscriptions.loading,
  productSubs: state.products.productSubscriptions.productSubsData,
  total: state.products.productSubscriptions.total,
  err: state.products.productSubscriptions.failed,
	token: state.login.token,
});
export default connect(mapStateToProps, {
  getProductDetails,
  getProductSubscriptions
})(ProductDetails);
